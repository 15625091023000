.login-footer {
  position: relative;
  background-color: hsla(0, 0%, 100%, 0.95);
  padding: 15px 0;
  text-align: center;
  box-shadow: 0 -2px 10px rgb(0 0 0 / 3%);
}

.login-footer img {
  margin: 5px;
  height: 25px;
}

.copyright-text {
  float: right;
  margin: 17px 20px 0 -155px;
  font-size: 12px;
  top: 10px;
  right: 20px;
  color: #555;
  font-style: italic;
}
@media (max-width: 991.98px) {
  .copyright-text {
    margin: 10px 0;
    width: 100%;
  }
}
