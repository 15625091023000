.login {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f3f5f7;
  overflow: auto;
}

.login-buttons {
  margin-top: 30px;
  text-align: center;
}

.otp-verification {
  border: 1px solid #ccc;
  padding: 0 20px 20px;
  background: #fff;
  margin: 20px;
}

.otp-inputstyle {
  width: 2.5rem !important;
  text-align: center !important;
  height: 4.5rem;
  margin: 0 1rem;
  font-size: 3rem;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

.otp-helper {
  color: rgb(130 130 130);
  font-style: italic;
  font-size: 14px;
  margin-bottom: 24px;
}
