.login {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f3f5f7;
  overflow: auto;
}

.login-buttons {
  margin-top: 30px;
  text-align: center;
}
.login-title {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 1.3;
  text-align: center;
  padding: 0px 30px;
}
