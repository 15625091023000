.login {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f3f5f7;
  overflow: auto;
}

.signin-title {
  margin-top: 24px;
  font-size: 18px;
  line-height: 1.6;
}

.login-buttons {
  margin-top: 30px;
  text-align: center;
}

.help-link {
  font-size: 0.8rem;
  margin-bottom: 15px;
}

.tnc {
  margin-bottom: 20px;
}

.password-group {
  margin-bottom: 0px;
}

.forgot-link {
  padding: 0;
  font-size: 0.8rem;
}
