@import "custom";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.center-h {
  -webkit-flex: 1 1;
  flex: 1 1;
  -webkit-align-self: center;
  -ms-grid-row-align: center;
  align-self: center;
  margin: 0 auto;
  padding-bottom: 50px;
  max-width: 600px;
}

.center-v {
  margin-top: 13vh;
  padding: 10px;
  color: #555;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 12px;
  font-weight: 400;
}

.form-control {
  width: 100%;
  height: 39px;
  padding: 8px 12px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  box-shadow: none;
}

.title {
  margin-bottom: 40px;
  font-size: 24px;
  line-height: 1.3;
  text-align: center;
  padding: 0 30px;
  color: #55c9f4;
}

.bsq-logo {
  max-width: 600px;
  background-position: 50%;
  margin: auto;
  background: url("./img/brightsquid.svg") no-repeat 0;
  height: 92px;
  background-position: 0;
}

img.app-logo {
  max-width: 600px;
  margin: auto;
  display: flex;
  margin-bottom: 8px;
}

.error-status {
  margin: 5px 0;
  color: #dd4040;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
}


.primary-button {
  font-size: 15px;
  border-radius: 0;
  background-color: #1074ae;
  margin-bottom: 20px;
  width: 100%;
  color: #fff;
}

.input-group-addon {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #ccc;
  border-radius: 0rem;
}